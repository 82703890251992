import {Footer} from "./Footer/Footer";
import {FirstScreen} from "./FirstScreen/FirstScreen";
import {ShortInfo} from "./ShortInfo/ShortInfo";
import {useCart} from "../cart/useCart";
import { BsBag } from "react-icons/bs";
import {useState} from "react";
import {Cart} from "../cart/Cart";
import {LangSelector} from "../LangSelector/LangSelector";

export const MainPage = () => {
    const {cart} = useCart();
    const [cartOpened, setCartOpened] = useState<boolean>(false);
    const toggleCartOpened = () => setCartOpened(!cartOpened);

    return (
        <div className="relative w-full">
            <LangSelector position={"left"}/>
            {cartOpened && (
                    <>
                        <Cart stateToggle={toggleCartOpened}/>
                    </>
            )}
            <>
            {cart.length > 0 && (
                <>
                    <button onClick={toggleCartOpened}
                        className="cart-button fixed top-0 right-0 mt-20 mr-6 p-6 border bg-white shadow-2xl rounded-full opacity-90 transition-all hover:shadow-md hover:translate-y-1">
                        <BsBag style={{fontSize: '36px'}}/>
                        <div
                            className="absolute flex justify-center items-center bottom-0 right-0 bg-black text-white p-2 rounded-full"
                            style={{width: '30px', height: '30px'}}>
                            {cart.length}
                        </div>
                    </button>
                </>
            )}
            </>
            <FirstScreen/>
            <ShortInfo/>
            <Footer/>
        </div>
    )
}