import FirstScreenIcon from "../../../assets/images/main-page/firstscreen.png";
import { FaAnglesDown } from "react-icons/fa6";
import './FirstScreen.css';
import {useTranslation} from "react-i18next";

export const FirstScreen = () => {
    const [t, i18n] = useTranslation("global");

    const handleClick = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className="firstscreen-preview w-full h-dvh bg-gray-300 flex flex-col items-center justify-center">


            {/* <img src="logo_leternite_black.png" className="firstscreen-logo"></img> 
            <h1 className="firstscreen-header select-none">L’éternité</h1> */}
                <img src="logo_leternite_large_black.png"   className="firstscreen-logo-full"></img>
                 {/* <img src="logo-bold.png"   className="firstscreen-logo-full"></img>  */}
                <p className="firstscreen-contact mt-5">contact@leternite.store</p>
                <FaAnglesDown
                    className="firstscreen-icon mt-10 text-gray-600 sm:text-gray-600 md:text-gray-400 lg:text-gray-400 xl:text-gray-400 cursor-pointer transition ease-in-out duration-300 hover:text-gray-500 hover:scale-105"
                    onClick={handleClick}
                />
            </div>
            <div className="w-full min-h-dvh flex flex-col items-center py-10 my-10 px-3">
                <img src={FirstScreenIcon} alt={"No image"} loading={"lazy"} className="firstscreen-image my-10"/>
                {/*<h2 className="firstscreen-text text-gray-500 sm:w-full md:w-3/4 lg:w-3/4 xl:w-2/3 text-center my-5">*/}
                {/*L’éternité is at the forefront of skincare innovation, offering revitalizing products that enhance and rejuvenate the skin. With cutting-edge formulations and high-quality ingredients, L’éternité delivers effective solutions for a radiant, youthful complexion.*/}
                {/*</h2>*/}
                <h2 className="firstscreen-text text-gray-500 sm:w-full md:w-3/4 lg:w-3/4 xl:w-2/3 text-center my-5">
                    {t("firstscreen.info")}
                </h2>
            </div>
        </>
    )
}