import './ShortInfo.css';
import facial_cleanser from "../../../assets/images/main-page/cleanser-for-site-1.jpg";
import hyalurone from "../../../assets/images/main-page/ha-serum-for-site-1.jpg";
import moisturiser from "../../../assets/images/main-page/moisturizer-for-site-1-1.jpg";
import {ReactComponent as Trees} from "../../../assets/images/main-page/re_trees.svg";
import {ReactComponent as Lamp} from "../../../assets/images/main-page/cowork_lamp.svg";
import {ReactComponent as Hand} from "../../../assets/images/main-page/8yo_hand.svg";

import left1 from "../../../assets/images/main-page/left.png"
import right1 from "../../../assets/images/main-page/right-1.png"
import right2 from "../../../assets/images/main-page/right-2.png"
import right3 from "../../../assets/images/main-page/right-3.png"
import right4 from "../../../assets/images/main-page/right-4.png"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";


export const ShortInfo = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <div className="w-full flex justify-center items-center py-10">
                <div
                    className="mx-3 py-10 shortinfo-content w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:px-4">
                    <NavLink to={"/products/1"} className="flex flex-col items-center">
                        <div className="w-full flex-1 flex items-center">
                            <img src={facial_cleanser} alt="Facial Cleanser"
                                 className="simple-highlight-image w-full h-auto cursor-pointer object-contain"/>
                        </div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">{t("products.cleanser-title")}</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">25$</p>
                    </NavLink>
                    <NavLink to={"/products/2"} className="flex flex-col items-center">
                        <div className="w-full flex-1 flex items-center">
                            <img src={hyalurone} alt="Hyaluronic Acid"
                                 className="simple-highlight-image w-full h-auto cursor-pointer object-contain"/>
                        </div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">{t("products.hyaluronic-title")}</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">24$</p>
                    </NavLink>
                    <NavLink to={"/products/3"} className="flex flex-col items-center">
                        <div className="w-full flex-1 flex items-center">
                            <img src={moisturiser} alt="Facial Moisturizer"
                                 className="simple-highlight-image w-full h-auto cursor-pointer object-contain"/>
                        </div>
                        <h3 className="shortinfo-title text-gray-500 text-center mt-4">{t("products.moisturizer-title")}</h3>
                        <p className="shortinfo-title text-xl text-center mt-4">24$</p>
                    </NavLink>
                </div>
            </div>
            <div className="w-full py-10">
                <div className="shortinfo-about grid grid-cols-1 md:grid-cols-2">
                    <div className="order-2 md:order-1 bg-gray-300 p-10" style={{backgroundColor: "#faf9f5"}}>
                        <h3 className="shortinfo-about-title p-10">{t("about.heading")}</h3>
                        <p className="shortinfo-about-text pt-6 p-10">{t("about.info")}</p>
                    </div>
                    <div className="shortinfo-about-image order-1 md:order-2 bg-gray-500 p-4"></div>
                </div>
            </div>
            <div className="w-full py-10 flex flex-col items-center">
                <h3 className="shortinfo-products-header py-10 mt-10 text-center sm:text-center">{t("shortinfo.heading")}</h3>
                <div
                    className="py-10 mt-10 mb-10 w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:px-4">
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Trees width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">{t("shortinfo.ingredients-heading")}</p>
                        <p className="text-sm text-center">{t("shortinfo.ingredients-info")}</p>
                    </div>
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Lamp width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">{t("shortinfo.design-heading")}</p>
                        <p className="text-sm text-center">{t("shortinfo.design-info")}</p>
                    </div>
                    <div className="flex flex-col items-center pb-10 px-8">
                        <Hand width='100px' height="100px"/>
                        <p className="text-xl py-10 text-center">{t("shortinfo.quality-heading")}</p>
                        <p className="text-sm text-center">{t("shortinfo.quality-info")}</p>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center py-24 px-10" style={{backgroundColor: '#faf9f5'}}>
                <h3 className="text-center followus-header py-14">{t("shortinfo.followus")}</h3>
                <div className="pb-20 w-full sm:w-full md:w-4/5 lg:w-3/4 xl:w-3/4 grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                    <div className="follow-column followus-photo border" style={{backgroundImage: `url(${left1})`}}></div>
                    <div className="follow-column grid gap-6 grid-cols-2">
                        <div className="followus-photo" style={{backgroundImage: `url(${right1})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right2})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right3})`}}></div>
                        <div className="followus-photo" style={{backgroundImage: `url(${right4})`}}></div>
                    </div>
                </div>
            </div>
        </>
    );
}