import React, { useState, useEffect } from "react";
import "flag-icons/css/flag-icons.min.css";
import { useTranslation } from "react-i18next";
import './LangSelector.css';

interface LangSelectorProps {
    position: 'left' | 'center' | 'right'
}

export const LangSelector = (props: LangSelectorProps) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [positionClasses, setPositionClasses] = useState<string>("top-5 left-5");

  const languages = [
    { code: 'en', name: 'English', country_code: 'us' },
    // { code: 'ru', name: 'Русский', country_code: 'ru' },
    { code: 'ua', name: 'Українська', country_code: 'ua' },
  ];

  useEffect(() => {
      positionClassesSwitch();
  }, []);

  useEffect(() => {
    const savedLang = localStorage.getItem('i18nextLng');
    if (savedLang && i18n.language !== savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const changeLanguage = async (code: string) => {
    await i18n.changeLanguage(code);
    localStorage.setItem('i18nextLng', code);
    setIsOpen(false);
  };

  const positionClassesSwitch = () => {
      switch (props.position) {
        case "left":
          setPositionClasses("top-11 left-5");
          break;
        case "center":
          setPositionClasses("fixed top-5 left-1/2 transform -translate-x-1/2");
          break;
        case "right":
          setPositionClasses("top-5 right-5 self-end");
          break;
        default:
          setPositionClasses("top-5 left-5");
      }
    }
  return (
    <div className={`lang-component fixed ${positionClasses}`}>
      <button
        onClick={toggleDropdown}
        className="shadow-gray-600 shadow-lg hover:shadow-sm outline-none bg-white rounded-sm flex items-center transition-all hover:scale-95"
      >
        <span className={`fi fi-${i18n.language === 'ua' ? 'ua' :  'us'} text-2xl`}></span>
      </button>
      {isOpen && (
        <div className="lang-menu mt-2 shadow-lg rounded-lg bg-transparent">
          {languages.map(({ code, name, country_code }) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              className="lang-flag-btn flex items-center w-full px-4 py-2 bg-white hover:bg-gray-200 focus:outline-none transition-all hover:translate-x-0.5"
            >
              <span className={`lang-flag fi fi-${country_code} mr-2`}></span>
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};