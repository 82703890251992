import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {global_en, global_ua, global_ru} from "./translations";
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'en',
    resources: {
        en: {
            global: global_en,
        },
        ru: {
            global: global_ru,
        },
        ua: {
            global: global_ua,
        }
    }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18next}>
          <App />
      </I18nextProvider>
  </React.StrictMode>
);

