import {useTranslation} from "react-i18next";


export const Footer  = () => {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <footer className="flex justify-center bg-gray-950 w-full py-8 sm:text-justify">
                <div className="flex flex-col items-center sm:w-full md:w-2/3 lg:w-2/3 xl:w-1/3 py-4 px-3">
                    <p className="text-gray-400 text-center" style={{fontSize: '12px', color: '#bcbcbc'}}>
                        {t("footer.info")}
                    </p>
                </div>
            </footer>
        </>
    )
}