import facial_cleanser from "../../assets/images/main-page/cleanser-for-site-1.jpg";
import cleaner_hover from "../../assets/images/main-page/cleanser-for-site-2.jpg";
import hyalurone from "../../assets/images/main-page/ha-serum-for-site-1.jpg";
import hyaluronic_hover from "../../assets/images/main-page/ha-serum-for-site-2.jpg";
import moisturiser from "../../assets/images/main-page/moisturizer-for-site-1-1.jpg";
import moisturizer_hover from "../../assets/images/main-page/moisturizer-for-site-2.jpg";

interface ProductsArrayProps{
    t: any;
    i18n: any;
}

export const ProductsArray = (props: ProductsArrayProps) => {
    const array = [
        {
            id: 1, images: [facial_cleanser, cleaner_hover], title: props.t("products.cleanser-title"), price: 25,
            description: props.t("products.cleanser-description"),
            ingredients: props.t("products.cleanser-ingredients")
        },
        {
            id: 2, images: [hyalurone, hyaluronic_hover], title: props.t("products.hyaluronic-title"), price: 24,
            description: props.t("products.hyaluronic-description"),
            ingredients: props.t("products.hyaluronic-ingredients")

        },
        {
            id: 3, images: [moisturiser, moisturizer_hover], title: props.t("products.moisturizer-title"), price: 24,
            description: props.t("products.moisturizer-description"),
            ingredients: props.t("products.moisturizer-ingredients")
        }
    ]
    return array
}