import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {MainPage} from "./components/main-page/MainPage";
import {Products} from "./components/products/Products";
import {ProductWrapper} from "./components/products/ProductWrapper";
import { ThemeProvider } from '@material-tailwind/react';
import { ProductsArray } from "./components/products/ProductsArray";
import {useTranslation} from "react-i18next";


function App() {
    const [t, i18n] = useTranslation("global");
    const products = ProductsArray({ t, i18n });

  return (
      <>
          <div className="App flex flex-col items-center w-full">
              <ThemeProvider>
                  <Router>
                      <Routes>
                          <Route path={"/"} element={<MainPage/>}/>
                          <Route path={"/index"} element={<MainPage/>}/>
                          <Route path="/products" element={<Products products={products}/>}/>
                          <Route path="/products/:id" element={<ProductWrapper products={products}/>}/>
                      </Routes>
                  </Router>
              </ThemeProvider>
          </div>
      </>
  );
}

export default App;
