import {useTranslation} from "react-i18next";

interface Product {
    id: number;
    title: string;
    price: number;
    description: string;
    ingredients: string;
}


interface ProductsProps {
    products: Product[];
}

export const Products = ({ products }: ProductsProps) => {
    const [t, i18n] = useTranslation("global");

    return (
        <div>
            <h1>{t("products.products")}</h1>
            <ul>
                {products.map(product => (
                    <li key={product.id}>
                        {product.title} - ${product.price}
                    </li>
                ))}
            </ul>
        </div>
    );
}